import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./components/Header/index"));
const Footer = lazy(() => import("./components/Footer/index"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));
const FormSubmitSuccess = lazy(() => import("./Pages/FormSubmitSuccess"));
const FallbackPage = lazy(() => import("./Pages/FallbackPage"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <GlobalStyle />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/form-submitted" element={<FormSubmitSuccess />} />
          <Route path="*" element={<FallbackPage />} /> {/* Catch-all route */}
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
